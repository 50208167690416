module.exports = {
    nav: {
        language: "语言",
        zh: "中文",
        en: "英文",
        mx: "墨西哥",
        home: "首页",
        service: "服务",
        aboutUs: "关于我们",
        contactUs: "联系我们",
    },
    home: {
        name: "国际 货运 代理",
    },
    service: {
        isoTank: "罐箱海运",
        fclAndLcl: "海运整柜和拼箱",
        fleteTerrestre: "墨西哥境内陆运（普通货物、危险货物、超大货物）",
        clearance: "清关",
        warehousing: "仓储（general cargo, dangerous, oversized）",
        maniobra: "拆柜和装柜服务",
        consultancy: "贸易咨询",
    },
    aboutUs: {
        info: "Starlux Logistics 是一家专注于提供罐箱业务的墨西哥本地物流、清关和外贸解决方案的货运代理公司。 作为墨西哥本土物流公司，我们的服务网络覆盖墨西哥港口和关口，如墨西哥城、曼萨尼约、拉萨罗卡德纳斯、韦拉克鲁斯、阿尔塔米拉和新拉雷多。",
        vision: "愿景",
        mission: "使命",
        values: "价值观",
        visionDetail: "成为世界知名的物流解决方案公司.",
        missionDetail: "寻找最有力的解决方案，满足客户的物流和外贸需求.",
        valuesItem1: "诚实",
        valuesItem2: "责任",
        valuesItem3: "忠诚",
        valuesItem4: "以客户为中心",
    },
    contactUs: {
        address: "地址",
        phone: "电话",
        email: "邮箱",
        addressDetail: "Calle Miguel Laurent 15 BIS, OFNA 404, Col. Tlacoquemecatl, Del. Benito Juaréz, CDMX, CP 03200",
        phoneDetailItem1: "0052 55 5636 3244",
        phoneDetailItem2: "55 7377 8559",
        emailDetailItem1: "Rebeca.zhang@starlux.mx",
        emailDetailItem2: "Elias.delarosa@starlux.mx",
    }
}
