module.exports = {
    nav: {
        language: "language",
        zh: "China",
        en: "English",
        mx: "Español",
        home: "Inicio",
        service: "Servicios",
        aboutUs: "Nosotros",
        contactUs: "Contáctanos",
    },
    home: {
        name: "INTERNATIONAL FREIGHT FORWARDER",
    },
    service: {
        isoTank: "Flete Marítimo ISO Tanque",
        fclAndLcl: "FCL LCL",
        fleteTerrestre: "Flete Terrestre a toda la República Mexicana （carga general, peligrosa, sobredimensionado）",
        clearance: "Despacho Aduanal",
        warehousing: "Almacenaje （carga general, peligrosa, sobredimensionado",
        maniobra: "Maniobra desycon (desconsolidación y consolidación)",
        consultancy: "Consultoría de Comercio Internacional",
    },
    aboutUs: {
        info: "Starlux Logistics es una empresa logística mexicana enfocada en brindar soluciones de isotanques en logística, aduana y comercio internacional. Operamos como una red sistemática de logística en los principales puertos y aduanas como: Ciudad de México, Manzanillo, Lázaro Cárdenas, Veracruz, Altamira y Nuevo Laredo.",
        vision: "Vision",
        mission: "Mission",
        values: "Values",
        visionDetail: "becoming a world-renowned logistics solution company.",
        missionDetail: "looking for the most powerful solution to meet customers' logistics and foreign trade needs",
        valuesItem1: "honest",
        valuesItem2: "responsibility",
        valuesItem3: "loyalty",
        valuesItem4: "customer-centric",
    },
    contactUs: {
        address: "Address",
        phone: "Phone",
        email: "Email",
        addressDetail: "Calle Miguel Laurent 15 BIS, OFNA 404, Col. Tlacoquemecatl, Del. Benito Juaréz, CDMX, CP 03200",
        phoneDetailItem1: "0052 55 5636 3244",
        phoneDetailItem2: "55 7377 8559",
        emailDetailItem1: "Rebeca.zhang@starlux.mx",
        emailDetailItem2: "Elias.delarosa@starlux.mx",
    }
}
