import Vue from "vue";
import App from "./App.vue";
import router from './router/router';
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from "./store";
import VueI18n from 'vue-i18n';
import cookie from 'js-cookie';
// 加载 element 组件库
import ElementUI from 'element-ui'
// 加载 element 组件库的样式
import 'element-ui/lib/theme-chalk/index.css'



Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);
// 全局注册 element 组件库
Vue.use(ElementUI)
Vue.prototype.cookie = cookie;

// 设置 cookie 工具函数
Vue.prototype.set_cookie = (name, data, expires) => {
  cookie.set(name, data, {
    expires: expires
  })
}

const i18n = {
  locale: cookie.get('lang') || 'en', // 语言标识,第一次登录默认是中文
  messages: {
    zh: require('@/language/local_zh'), // 汉语
    en: require('@/language/local_en'), // 英语
    mx: require('@/language/local_mx'), // 墨西哥
  }
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
