<template>
  <div>
    <b-containr>
      <b-row align-h="center" class="in-center" style="background-color: #5c5763">
        <p>© Copyright 2023. All rights reserved.</p>
      </b-row>
    </b-containr>
  </div>
</template>

<script>
export default {
  name: "MyFooter",
};
</script>

<style lang="less" scoped>
a {
  width: auto !important;
  color: #7e7e7e;
  display: inline-block;
  text-decoration: underline;
  margin-right: 1rem;
}

p {
  width: auto !important;
}

a:hover,
a:active {
  color: #000000;
}
</style>
