<template>
  <div ref="box">
    <b-nav small>
      <b-nav-item href="#"><p class="h7 mb-2"> <b-icon icon="phone"></b-icon>55 5575 1084</p></b-nav-item>
      <b-nav-item href="#"><p class="h7 mb-2"> <b-icon icon="envelope"></b-icon>Rebeca.zhang@starlux.mx</p></b-nav-item>
      <b-nav-item-dropdown :text="language" left>
        <b-dropdown-item @click="lang_change(1)">{{ $t("nav.zh") }}</b-dropdown-item>
        <b-dropdown-item @click="lang_change(2)">{{ $t("nav.en") }}</b-dropdown-item>
        <b-dropdown-item @click="lang_change(3)">{{ $t("nav.mx") }}</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-nav>
    <b-navbar type="dark" variant="dark" fixed="">
      <b-navbar-brand to="/home" class="mx-3">STARLUX</b-navbar-brand>
      <b-navbar-nav class="ml-auto mx-3">
<!--        <b-nav-item href="#" @click="scrollto('myLux')">{{ $t("nav.home") }}</b-nav-item>-->
        <b-nav-item href="#" @click="scrollto('myService')">{{ $t("nav.service") }}</b-nav-item>
        <b-nav-item href="#" @click="scrollto('aboutUs')">{{ $t("nav.aboutUs") }}</b-nav-item>
        <b-nav-item href="#" @click="scrollto('contactUs')">{{ $t("nav.contactUs") }}</b-nav-item>
      </b-navbar-nav>
    </b-navbar>

    <div class="backImg" id="myLux">
      <p>STARLUX LOGISTICS SA DE CV</p>
    </div>

    <b-container style="padding-top: 60px" id="myService">
      <b-row>
        <b-col>
          <h1 style="font-size: 55px;text-align: center;margin-bottom: 70px">{{ $t("nav.service") }}</h1>
        </b-col>
      </b-row>
      <b-row align-v="center" align-h="center" class="mb-5">
        <b-col cols="12" md="6" order-md="1" mb="1">
          <div
              class="rounded-lg"
              style="height: 300px; background: url('service/tankContainer.jpg');background-size: cover;"
          ></div>
        </b-col>
        <b-col cols="12" md="6" mb="1" order-md="2" class="mb-5">
          <h3 class="text-sm-center text-md-left text-center">
            {{ $t("service.isoTank") }}
          </h3>
          <p class="text-sm-center text-md-left text-center">
          </p>
        </b-col>
      </b-row>

      <b-row align-v="center" align-h="center" class="mb-5">
        <b-col cols="12" md="6" order-md="2" mb="1">
          <div
              class="rounded-lg"
              style="height: 300px; background: url('service/oceanPort.jpg')"
          ></div>
        </b-col>

        <b-col cols="12" md="6" mb="1" order-md="1" class="mb-5">
          <h3 class="text-sm-center text-md-left text-center">
            {{ $t("service.fclAndLcl") }}
          </h3>
          <p class="text-sm-center text-md-left text-center">
          </p>
        </b-col>
      </b-row>


      <b-row align-v="center" align-h="center" class="mb-5">
        <b-col cols="12" md="6" order-md="1" mb="1">
          <div
              class="rounded-lg"
              style="height: 300px; background: url('service/transport.jpeg')"
          ></div>
        </b-col>

        <b-col cols="12" md="6" mb="1" order-md="2" class="mb-5">
          <h3 class="text-sm-center text-md-left text-center">
            {{ $t("service.fleteTerrestre") }}
          </h3>
          <p class="text-sm-center text-md-left text-center">
          </p>
        </b-col>
      </b-row>

      <b-row align-v="center" align-h="center" class="mb-5">
        <b-col cols="12" md="6" order-md="2" mb="1">
          <div
              class="rounded-lg"
              style="height: 300px; background: url('service/cleanPort.jpg')"
          ></div>
        </b-col>

        <b-col cols="12" md="6" mb="1" order-md="1" class="mb-5">
          <h3 class="text-sm-center text-md-left text-center">
            {{ $t("service.clearance") }}
          </h3>
          <p class="text-sm-center text-md-left text-center">
          </p>
        </b-col>
      </b-row>

      <b-row align-v="center" align-h="center" class="mb-5">
        <b-col cols="12" md="6" order-md="1" mb="1">
          <div
              class="rounded-lg"
              style="height: 300px; background: url('service/warhouse.jpeg')"
          ></div>
        </b-col>

        <b-col cols="12" md="6" mb="1" order-md="2" class="mb-5">
          <h3 class="text-sm-center text-md-left text-center">
            {{ $t("service.warehousing") }}
          </h3>
          <p class="text-sm-center text-md-left text-center">
          </p>
        </b-col>
      </b-row>

      <b-row align-v="center" align-h="center" class="mb-5">
        <b-col cols="12" md="6" order-md="2" mb="1">
          <div
              class="rounded-lg"
              style="height: 300px; background: url('service/maniobra.jpg')"
          ></div>
        </b-col>

        <b-col cols="12" md="6" mb="1" order-md="1" class="mb-5">
          <h3 class="text-sm-center text-md-left text-center">
            {{ $t("service.maniobra") }}
          </h3>
          <p class="text-sm-center text-md-left text-center">
          </p>
        </b-col>
      </b-row>

      <b-row align-v="center" align-h="center" class="mb-5">
        <b-col cols="12" md="6" order-md="1" mb="1">
          <div
              class="rounded-lg"
              style="height: 300px; background: url('service/advice.jpg')"
          ></div>
        </b-col>

        <b-col cols="12" md="6" mb="1" order-md="2" class="mb-5">
          <h3 class="text-sm-center text-md-left text-center">
            {{ $t("service.consultancy") }}
          </h3>
          <p class="text-sm-center text-md-left text-center">
          </p>
        </b-col>
      </b-row>
    </b-container>

    <b-jumbotron header="" lead="" id="aboutUs">
      <b-container fluid="lg">
        <!-- 区域分割线 -->
        <b-row>
          <b-col>
            <h1 style="font-size: 55px;text-align: center;margin-bottom: 70px">{{ $t("nav.aboutUs") }}</h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" order="2">
            <div
                class="rounded-lg"
                style="
              height: 200px;
              background: url('aboutUs/teamwork.jpg');
            "
            ></div>
          </b-col>
          <b-col cols="12" md="8" order="1" class="mt-3">
            <p class="text-sm-center text-md-left text-center">
              {{ $t("aboutUs.info") }}
            </p>
          </b-col>
        </b-row>
<!--        <b-row align-h="center" class="mb-5">-->
<!--          <b-col cols="12" md="4" lg="4">-->
<!--            <div class="in-center">-->
<!--              <b-card-->
<!--                  :title="vision"-->
<!--                  img-src="aboutUs/0.jpg"-->
<!--                  img-alt="Image"-->
<!--                  img-top-->
<!--                  tag="article"-->
<!--                  class="mb-2"-->
<!--              >-->
<!--                <b-card-text>-->
<!--                  <p>-->
<!--                    {{ $t("aboutUs.visionDetail") }}.-->
<!--                  </p>-->
<!--                </b-card-text>-->
<!--              </b-card>-->
<!--            </div>-->
<!--          </b-col>-->

<!--          <b-col cols="12" md="4" lg="4">-->
<!--            <div class="in-center">-->
<!--              <b-card-->
<!--                  :title="mission"-->
<!--                  img-src="aboutUs/1.jpg"-->
<!--                  img-alt="Image"-->
<!--                  img-top-->
<!--                  tag="article"-->
<!--                  class="mb-2"-->
<!--              >-->
<!--                <b-card-text>-->
<!--                  <p>-->
<!--                    {{ $t("aboutUs.missionDetail") }}.-->
<!--                  </p>-->
<!--                </b-card-text>-->
<!--              </b-card>-->
<!--            </div>-->
<!--          </b-col>-->
<!--          <b-col cols="12" md="4" lg="4">-->
<!--            <div class="in-center">-->
<!--              <b-card-->
<!--                  :title="values"-->
<!--                  img-src="aboutUs/2.jpg"-->
<!--                  img-alt="Image"-->
<!--                  img-top-->
<!--                  tag="article"-->
<!--                  class="mb-2"-->
<!--              >-->
<!--                <b-card-text>-->
<!--                  <ul>-->
<!--                    <li>{{ $t("aboutUs.valuesItem1") }}.</li>-->
<!--                    <li>{{ $t("aboutUs.valuesItem2") }}.</li>-->
<!--                    <li>{{ $t("aboutUs.valuesItem3") }}.</li>-->
<!--                    <li>{{ $t("aboutUs.valuesItem4") }}.</li>-->
<!--                  </ul>-->
<!--                </b-card-text>-->
<!--              </b-card>-->
<!--            </div>-->
<!--          </b-col>-->
<!--        </b-row>-->
        <!-- Content here -->
      </b-container>
    </b-jumbotron>

    <b-container fluid="lg" id="contactUs">
      <!-- 区域分割线 -->
      <b-row>
        <b-col>
          <h1 style="font-size: 55px;text-align: center;margin-bottom: 70px">{{ $t("nav.contactUs") }}</h1>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mb-5">
        <b-col cols="12" md="4" lg="4">
          <div class="in-center">
            <b-icon icon="cursor" font-scale="4"></b-icon>
            <h4 style="margin-top: 10px">{{ $t("contactUs.address") }}</h4>
            <p>{{ $t("contactUs.addressDetail") }}</p>
          </div>
        </b-col>
        <b-col cols="12" md="4" lg="4">
          <div class="in-center">
            <b-icon icon="chat" font-scale="4"></b-icon>
            <h4 style="margin-top: 10px">{{ $t("contactUs.phone") }}</h4>
            <p>{{ $t("contactUs.phoneDetailItem1") }}<br>{{ $t("contactUs.phoneDetailItem2") }}</p>
          </div>
        </b-col>
        <b-col cols="12" md="4" lg="4">
          <div class="in-center">
            <b-icon icon="envelope" font-scale="4"></b-icon>
            <h4 style="margin-top: 10px">{{ $t("contactUs.email") }}</h4>
            <p>{{ $t("contactUs.emailDetailItem1") }}<br>{{ $t("contactUs.emailDetailItem2") }}</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <MyFooter/>
  </div>
</template>

<script>
import MyFooter from "../components/MyFooter.vue"
import NavBar from '../components/MyNavBar.vue'

export default {
  name: "HomePage",
  components: {
    'nav-bar': NavBar,
    MyFooter,
  },
  data() {
    return {
      language: this.$t('nav.language'),
      vision: this.$t('aboutUs.vision'),
      mission: this.$t('aboutUs.mission'),
      values: this.$t('aboutUs.values'),
      items: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Manage',
          href: '#'
        },
        {
          text: 'Library',
          active: true
        }
      ]
    };
  },
  methods: {
    scrollto(id) {
      const dom = document.getElementById(id)
      if (dom && this.$refs.box) {
        window.scrollTo({
          top:dom.offsetTop, //y 上线
          left:0,//x 左右
          behavior:'smooth',//属性值：默认值auto（相当于instant）、平滑滚动smooth、瞬间滚动instant
        });
      }
    },
    lang_change(value) {
      if (value == 1) {
        this.$i18n.locale = "zh";
        this.language = this.$t('nav.language');
        this.vision = this.$t('aboutUs.vision');
        this.mission = this.$t('aboutUs.mission');
        this.values = this.$t('aboutUs.values');
        this.set_cookie('lang', 'zh', 6); // 第一个参数是名字，第二个参数是当前的语言，第三个参数是表示 cookie 过期时间，可能是6（天）
      } else if(value == 2) {
        this.$i18n.locale = "en";
        this.language = this.$t('nav.language');
        this.vision = this.$t('aboutUs.vision');
        this.mission = this.$t('aboutUs.mission');
        this.values = this.$t('aboutUs.values');
        this.set_cookie('lang', 'en', 6);
      } else if(value == 3) {
        this.$i18n.locale = "mx";
        this.language = this.$t('nav.language');
        this.vision = this.$t('aboutUs.vision');
        this.mission = this.$t('aboutUs.mission');
        this.values = this.$t('aboutUs.values');
        this.set_cookie('lang', 'mx', 6);
      }
    }
  },
};
</script>

<style scoped>
.display-3 {
  margin-top: 56px;
}

.form-group {
  padding: 26px;
}

h3 {
  font-size: 1.75rem !important;
  text-align: left !important;
}

h2 {
  font-size: 2rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.text-center {
  text-align: center !important;
  margin-bottom: 1rem;
}

.in-center {
  text-align: center !important;
}

.backImg {
  background-image: url("../assets/home.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 800px;
}

.backImg p {
  font-size: 75px;
  color: rgba(4, 75, 197, 0.65);
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-40%, -40%);
}

@media screen and (max-width: 768px) {
  .backImg {
    background-image: url("../assets/home.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 300px;
  }
  .backImg p {
    font-size: 25px;
    color: rgba(4, 75, 197, 0.65);
    position: absolute;
    top: 35%;
    left: 30%;
    transform: translate(-25%, -40%);
  }
}
</style>
