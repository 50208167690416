import Vue from "vue"
import VueRouter from "vue-router"
import HomePage from "../views/HomePage.vue"
import MyFooter from "../components/MyFooter.vue"
import store from "@/store"


Vue.use(VueRouter);
const routes = [
    { path: '/', redirect: '/home' },
    {
        path: "/home",
        name: "home",
        component: HomePage,
        meta: {
            title: 'Starlux',
        }
    },
]

const router = new VueRouter({
    mode: "hash",
    //   base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.meta.title){
        document.title = to.meta.title
    }
    next()
})

export default router;
