module.exports = {
    nav: {
        language: "language",
        zh: "China",
        en: "English",
        mx: "Español",
        home: "Home",
        service: "Services",
        aboutUs: "About Us",
        contactUs: "Contact Us",
    },
    home: {
        name: "INTERNATIONAL FREIGHT FORWARDER",
    },
    service: {
        isoTank: "Ocean Freight ISOTANK",
        fclAndLcl: "Ocean Freight FCL and LCL",
        fleteTerrestre: "Land freight to the entire Mexican Republic（general cargo, dangerous, oversized）",
        clearance: "Custom Clearance",
        warehousing: "Warehousing （general cargo, dangerous, oversized）",
        maniobra: "Deconsolidation and consolidation maneuver",
        consultancy: "International trade consultancy",
    },
    aboutUs: {
        info: "Starlux Logistics is a freight forwarder agency and specialist in isotank in Logistics, Customs and Foreign Trade solutions at Mexico. We operate as a Logistics systematic network in the main ports and custom houses of the Mexican Republic, such as Mexico City, Manzanillo, Lazaro Cardenas, Veracruz, Altamira and Nuevo Laredo.",
        vision: "Vision",
        mission: "Mission",
        values: "Values",
        visionDetail: "becoming a world-renowned logistics solution company.",
        missionDetail: "looking for the most powerful solution to meet customers' logistics and foreign trade needs",
        valuesItem1: "honest",
        valuesItem2: "responsibility",
        valuesItem3: "loyalty",
        valuesItem4: "customer-centric",
    },
    contactUs: {
        address: "Address",
        phone: "Phone",
        email: "Email",
        addressDetail: "Calle Miguel Laurent 15 BIS, OFNA 404, Col. Tlacoquemecatl, Del. Benito Juaréz, CDMX, CP 03200",
        phoneDetailItem1: "0052 55 5636 3244",
        phoneDetailItem2: "55 7377 8559",
        emailDetailItem1: "Rebeca.zhang@starlux.mx",
        emailDetailItem2: "Elias.delarosa@starlux.mx",
    }
}
