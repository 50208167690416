<template>
  <div>
    <b-navbar type="dark" variant="dark" fixed="top">
        <b-navbar-nav>
          <b-nav-item href="#"><p class="h7 mb-2"> <b-icon icon="phone"></b-icon>(+52) 56 3533-0652</p></b-nav-item>
          <b-nav-item href="#"><p class="h7 mb-2"> <b-icon icon="envelope"></b-icon>sales@reshipping.com.mx</p></b-nav-item>
          <b-nav-item-dropdown text="Lang" left>
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">ES</b-dropdown-item>
            <b-dropdown-item href="#">RU</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="#" @click="scrollto('myService')">我们的服务</b-nav-item>
          <b-nav-item href="#">关于我们</b-nav-item>
          <b-nav-item href="#">联系我们</b-nav-item>
        </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  components:{
  },
  name: "NavBar",
  data() {
    return {
        mainProps: { blank: true, blankColor: '#777', width: 75, height: 75, class: 'm1' }
    };
  },
  methods:{
    scrollto(id) {
      const dom = document.getElementById(id)
      console.log(dom)
      console.log(this.$refs.box)
      if (dom && this.$refs.box) {
        this.$refs.box.scrollTo({
          top: dom.offsetTop - 20,
          behavior: "smooth",
        })
        // window.scrollTo({
        //   top:dom.offsetTop, //y 上线
        //   left:0,//x 左右
        //   behavior:'smooth',//属性值：默认值auto（相当于instant）、平滑滚动smooth、瞬间滚动instant
        // });
      }
    }
  },
  computed:{
    ...mapState(["user"]),
    hasLogin(){
      return this.user
    }
  }
};
</script>

<style scoped lang="less">
.right {
  justify-content: space-between;
}
</style>
